
<template>
  <div class="compressor-container">
    <div class="result-card">
      <image-compressor>
      </image-compressor>
    </div>
  </div>
</template>

<script setup>
import ImageCompressor from "@/components/image-compressor/index.vue";
import { ref } from "vue";
import { useMeta } from 'vue-meta'
useMeta({
  title: 'Compress images as you desire - Boolv.Toolkit',
  description: "Compress JPEG,WEBP,JPG, PNG images and photos online for free now. You can use our Image Compressor tool to make it ideal for printing, publishing to social media, and other uses.",
  htmlAttrs: { lang: 'en', amp: true }
})
</script>

<style lang="less">

</style>
