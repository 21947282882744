<template>
  <div class="model-status-background">
    <div class="model-status-flex">
      <div>
        <video :src="loadingMp4" id="video-icon">
        </video>
      </div>
      <div class="model-status">{{ message }}</div>
    </div>
  </div>
</template>

<script setup>

import { ref, watchEffect, defineProps, onMounted } from 'vue'
import loadingMp4 from '../assets/loading.mp4'

const props = defineProps({
  modelLoading: Boolean,
  modelInitializing: Boolean
})

const message = ref('')
onMounted(() => {
  const element = document.getElementById("video-icon");
  element?.play();
})
watchEffect(() => {

  if (props.modelLoading) {
    message.value = "Loading model...";

  } else if (props.modelInitializing) {
    message.value = "Loading model done. Initializing model...";
  } else {
    message.value = "";
  }
})

</script>

<style scoped lang="less">
.model-status-background {
  position: absolute;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 1;
  justify-content: center;
  text-align: center;

  font-size: 16px;
  line-height: 20px;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  color: #000000;
}

.model-status {
  padding: 30px;
  margin-top: 100px;
  font-size: 25px;
  color: var(--color-blue);
  position: relative;
  top: 0px;
  opacity: 100;
  z-index: 5;
  display: center;
  margin: 0 auto;
}

#video-icon {
  width: 100px;
  height: 100px;
}

.model-status-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>
