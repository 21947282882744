<template>
  <div>
    <!-- 模型加载loading -->
    <model-status v-if="modelLoading || modelInitializing" :modelLoading="modelLoading"
      :modelInitializing="modelInitializing"></model-status>
    <div class="back-ground-remover-wrap" :style="{ 'padding-top': imageHandleComplete ? '48px' : '180px' }">
      <h1 class="tool-title" :style="{ 'margin-bottom': imageHandleComplete ? '30px' : '52px' }">
        Image Compressor
      </h1>
      <!-- 上传组件 -->
      <div class="common-upload-box">
        <common-upload @changFileList="changFileList" @changSelectImage="changSelectImage" :modelLoading="modelLoading"
          :modelInitializing="modelInitializing" :modelLoadingError="modelLoadingError" :imageUrls="imageUrls"
          :modelInProgress="modelInProgress" :imageHandleComplete="imageHandleComplete" :changeImage="changeImage"
          :uploadPlaceholder="uploadPlaceholder" />
      </div>


      <!-- compressor -->
      <div class="compressor-container" :style="{
        opacity: imageHandleComplete ? 1 : 0,
        marginTop: imageHandleComplete ? '68px' : '-200px',
        pointerEvents: imageHandleComplete ? 'all' : 'none'
      }">
        <!-- 原图 -->
        <div class="image-show-container">
          <div class="image-show-box" @mouseenter="handleMattingBoxMouseEnter" @mouseleave="handleMattingBoxMouseLeave">
            <div class="image-show-compared-button" @mousedown="handleComparedButtonMousedown"
              @mouseup="handleComparedButtonMouseup">
              <img :src="compared_icon" />
              <div class="image-show-tips" v-show="showTips">
                <div class="image-show-tips-arrow"></div>
                <div class="image-show-tips-box">Compared</div>
              </div>
            </div>
            <div class="buttons-container" v-if="showMattingEditBtns">
              <a-button type="primary" @click="downloadImage()">Download</a-button>
            </div>
            <img :src="imageSrc" :width="imageWidth" :height="imageHeight" />
            <!-- <img :src="compressedImage sourceImageurl" :width="imageWidth" :height="imageHeight" v-else /> -->
          </div>
        </div>
        <!-- 右侧信息展示区域 -->
        <div class="infomation-show-container">
          <!-- Compression Slider -->
          <div class="compression-slider">
            <div class="infomation-compression-percentage">
              <h2 class="compression-text">Compression </h2>
              <span>{{100- compressedValue }}%</span>
            </div>
            <a-slider v-model:value="compressedValue" :tooltipVisible="false" :min="0" :max="100" class="antd-slider"
              @change="handleSizeChange" />
            <p class="warning-tip">{{warningTip}}</p>
          </div>
          <!--------size对比--------->
          <div class="compression-size-compared-box">
            <div class="compression-size-source-image">
              <h3 class="compression-size-source-text">Source Image</h3>
              <span class="infomation-compression-size">{{ fileInfo.sourceSize?.slice(0, -2) }}</span>
              <span>{{ fileInfo.sourceSize?.slice(-2) }}</span>
            </div>
            <div class="infomation-compression-divider"></div>
            <div class="compression-size-compressed-image">
              <h3 class="compression-size-source-text">Compressed Image</h3>
              <span class="infomation-compression-size">{{ fileInfo.compressedSize?.slice(0, -2) }}</span>
              <span>{{ fileInfo.compressedSize?.slice(-2) }}</span>
            </div>
          </div>
          <!--------Compressed Image button--------->
          <div class="compression-sure-button">
            <a-button type="primary" @click="handleCompressedSizeChange"><img :src="compressor_arrow" />Compressed Image
            </a-button>
          </div>
          <!--------save size infomation text--------->
          <!-- <div>
            <div>Saved you {{"todo"}} total </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ModelStatus from "../modelStatus.vue";
import { onMounted, ref, defineProps, watchEffect } from "vue";
import CommonUpload from "../commonUpload.vue";
import Compressor from "compressorjs";
import compressor_arrow from '../../assets/compressor-arrow.svg'
import compared_icon from '../../assets/compared.svg'
import cat_icon from '../../assets/cat_icon.png'
import cat_src from '../../assets/cat_src.png'
import { reportData } from '@/utils/buriedFunction/reportData.js'
var _ = require('lodash');
var md5 = require("md5");

const props = defineProps({});
const uploadPlaceholder = ref('')
const imageHandleComplete = ref(false);
const modelLoading = ref(false);
const changeImage = ref(false);
const modelInProgress = ref('start');
const modelLoadingError = ref(false);
const modelInitializing = ref(false);

const compressedQuality = ref("0.80");
const showTips = ref(true);
const fileInfo = ref({});
const sourceImageurl = ref('');
const imageSrc = ref('');
const compressedImage = ref(null);
const compressedValue = ref(80);
const imageWidth = ref(0)
const imageHeight = ref(0)
const sourceImageShow = ref(false);
const showMattingEditBtns = ref(false);
const warningTip = ref('')

const imageUrls = ref([
  {
    id: 1,
    iconSrc: cat_src,
    src: cat_src,
    width: 165,
    height: 110,
    marginRight: 0
  }
])

watchEffect(() => {
  if(compressedValue.value <= 20) {
    warningTip.value = 'Compression > 80% will affect the image quality.'
  }else {
    warningTip.value = ''
  }
})

const changSelectImage = async (url) => {
  //清空图片信息
  fileInfo.value = {}
  modelInProgress.value = 'progressing';
  if (imageHandleComplete.value) {
    imageHandleComplete.value = false;
  }
  //加载这张image
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], 'boolv', { type: blob.type });
  //获取图片信息
  fileInfo.value = {
    sourceFile: file,
    fileName: file.name,
    type: file.type,
    sourceSize: niceBytes(file.size),
    compressedSize: "",
    compressedFile: "",
    compressedImageUrl: "",
  }
  const result = await compressorImage(file, { quality: 0.8 })
  fileInfo.value.compressedSize = niceBytes(result.size)
  fileInfo.value.compressedFile = result

  //得到这张file ，输出为url
  sourceImageurl.value = URL.createObjectURL(file);
  compressedImage.value = URL.createObjectURL(result);
  imageSrc.value = compressedImage.value
  const [w, h] = await getWidth(sourceImageurl.value)
  imageWidth.value = w
  imageHeight.value = h
  imageHandleComplete.value = true;
  modelInProgress.value = 'done';
  uploadPlaceholder.value = 'Change an image here'

};

const handleSizeChange = _.debounce(async function (value) {
  compressedQuality.value = value / 100
  const result = await compressorImage(fileInfo.value.sourceFile, { quality: value / 100 })
  fileInfo.value.compressedSize = niceBytes(result.size)
  fileInfo.value.compressedFile = result
}, 500)



function niceBytes(x) {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0, n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

const changFileList = async (fileList) => {
  if (!fileList || !fileList.length) {
    return;
  }
  modelInProgress.value = 'progressing';
  if (imageHandleComplete.value) {
    changeImage.value = true;
    imageHandleComplete.value = false;
  }

  const file = fileList[fileList.length - 1].originFileObj

  fileInfo.value = {
    sourceFile: file,
    fileName: file.name,
    type: file.type,
    sourceSize: niceBytes(file.size),
    compressedSize: "",
    compressedFile: "",
    compressedImageUrl: "",
  }
  const result = await compressorImage(file, { quality: 0.8 })
  fileInfo.value.compressedSize = niceBytes(result.size)
  fileInfo.value.compressedFile = result

  //得到这张file ，输出为url
  sourceImageurl.value = URL.createObjectURL(file);
  compressedImage.value = URL.createObjectURL(result);
  imageSrc.value = compressedImage.value
  const [w, h] = await getWidth(sourceImageurl.value)
  imageWidth.value = w
  imageHeight.value = h
  imageHandleComplete.value = true;
  modelInProgress.value = 'done';

};

const handleCompressedSizeChange = function () {
  compressedImage.value = URL.createObjectURL(fileInfo.value.compressedFile);
  imageSrc.value = compressedImage.value
}

const compressorImage = (file, options) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: options.quality,
      convertSize: 0,
      success(result) {
        resolve(result)

      },
      error(err) {
        reject(err)
      },
    });
  })

}

const getWidth = (url) => {
  console.log('[ url ] >', url)
  var img = new Image;
  img.src = url;
  return new Promise((resolve, reject) => {
    img.onload = function () {
      const { width, height } = img
      if (width > height) {
        const w = 540, h = (540 * height) / width
        resolve([w, h])
      } else if (height > width) {
        const h = 540, w = (540 * width) / height
        resolve([w, h])
      } else {
        resolve([540, 540])
      }
    };

  })


}

const handleComparedButtonMousedown = () => {
  imageSrc.value = sourceImageurl.value
  showTips.value = false
  sourceImageShow.value = true;
}
const handleComparedButtonMouseup = () => {
  imageSrc.value = compressedImage.value
  sourceImageShow.value = false;
  showTips.value = true;
}

const handleMattingBoxMouseEnter = () => {

  showMattingEditBtns.value = true;
};

const handleMattingBoxMouseLeave = () => {
  showMattingEditBtns.value = false;
};

const downloadImage = () => {
  //get 当前下载的图片的名称和格式
  const md5Name = md5(Date.now())
  reportData("toolkits_compressor_download")
  var link = document.createElement("a");
  link.download = `image-Compress-${md5Name}`;
  link.href = compressedImage.value;
  link.click();
};

</script>
<style lang="less" scoped>
.compressor-container {
  width: 100%;
  margin-top: 82px;
  display: flex;
  justify-content: center;
  -webkit-user-drag: none;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */


  /* tex1 */

  color: #060606;
}

.back-ground-remover-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.common-upload-box {
  width: 604px;
  height: 92px;
  border: 2px dashed #878787;
  border-radius: 6px;
}

.container {
  margin-right: 118px;
}

.tool-title {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  margin-bottom: 52px;
}

.image-show-container {
  margin-right: 68px;
}

.image-show-box {
  height: 540px;
  background: #F7F7F7;
  width: 540px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-show-compared-button {
  width: 42px;
  height: 42px;
  position: absolute;
  right: 16px;
  top: 16px;
  background: #FFFFFF;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.infomation-show-container {
  min-width: 374px;

}

.compression-slider {
  text-align: left;
  padding-bottom: 20px;
  margin-bottom: 40px;
  color: #575757;
}

.infomation-compression-percentage {
  margin-bottom: 26px;
}

.warning-tip {
  color: #969696;
  font-size: 14px;
  font-family: 'Helvetica Neue';
  font-weight: 400;
  margin-bottom: 0;

}

.compression-size-compared-box {
  display: flex;
  background: #F7F7F7;
  border-radius: 7px;
  padding: 14px 28px;
  position: relative;
  margin-bottom: 54px;
}

.infomation-compression-size {
  color: #444444;
  font-size: 36px;
  margin-top: 16px;
  line-height: 43px;
}

.compression-size-source-image {
  margin-left: 16px;
}

.infomation-compression-divider {
  width: 1px;
  height: 72px;
  border: 0.5px solid #DEDEDE;
  position: absolute;
  left: 50%;
}

.compression-size-compressed-image {
  position: absolute;
  left: 50%;
  margin-left: 18px;
}

h3{
  font-size: 16px;
  font-weight: 400;
}
.compression-size-source-text {
  margin-bottom: 10px;
}

.compression-sure-button {
  text-align: left;
}

.ant-btn-primary {
  background: #875EFF;
  border-radius: 500px;
  width: 204px;
  height: 54px;
  font-size: 16px;
  line-height: 19px;
}

.ant-slider .ant-slider-rail {
  border-radius: 60px;
  background-color: #D9D9D9;
}

.ant-slider .ant-slider-handle {
  border: solid 2px #575757;
}

.image-show-compared-button:hover .image-show-tips {
  visibility: visible;
}

.image-show-compared-button:hover {
  background: #D9D9D9;
}

.image-show-tips {
  visibility: hidden;
  position: absolute;
  top: 42px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.image-show-tips-arrow {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom: 6px solid #1F2329;
}

.image-show-tips-box {
  color: #FFFFFF;
  width: 83px;
  height: 36px;
  background: #1F2329;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 20px;
}

.buttons-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 80px 14px 80px;
  display: flex;
  justify-content: center;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;


  .ant-btn {
    width: 120px;
    height: 36px;
    border-radius: 200px;
    color: #fff;
    border: none;
  }

  .ant-btn-default {
    background: rgba(0, 0, 0, 0.8);
    opacity: 0.8;
  }

  .ant-btn-primary {
    opacity: 1;
    background: #875eff;
  }
}

:global(.ant-slider-rail) {
  border-radius: 60px;
  background-color: #D9D9D9;
}

:global(.ant-slider-handle) {
  border: 2px solid #575757;
}

:global(.ant-slider-track) {
  background-color: #575757;
}

:global(.ant-slider:hover .ant-slider-track) {
  background-color: #575757;
}

:global(.ant-slider:hover .ant-slider-rail) {
  background-color: #D9D9D9;
}

:global(.ant-slider:hover .ant-slider-handle) {
  border: 2px solid #575757;
}

:global(.ant-slider-handle:focus) {
  border: 2px solid #575757;
  box-shadow: none;
}

.compression-text{
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #575757;
  margin-right: 6px;
}
:global(.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open)) {
  border: 2px solid #575757;
}

img {
  -webkit-user-drag: none;
}
</style>